
import { Component, Vue } from 'vue-property-decorator';

import { Store } from 'vuex';
import { IOfferFilter, IOffer } from '@/interfaces';
import { readOffersFilter, readOffers, readRowsPerPage } from '@/store/main/getters';
import { dispatchUpdateOfferFilter, dispatchUpdateRowsPerPage } from '@/store/main/actions';

@Component
export default class SearchGoods extends Vue {
    public offers_search: string = '';
    public selectedRows = [];
    public pagination = {}

    public headers = [
        {text: 'Название файла', value: 'ya_path', class: 'sticky', sortable: true, align: 'left'},
        {text: 'Наименование товара', value: 'title', sortable: true, align: 'left'},
        {text: 'Марка/Модель', value: 'model', sortable: true, align: 'left'},
        {text: 'ГОСТ', value: 'gost', sortable: true, align: 'left'},
        {text: 'Производитель', value: 'producer', sortable: true, align: 'left'},
        {text: 'Гарантийный срок', value: 'warranty_period', sortable: true, align: 'left'},

    ];
    public headers_xlsx = [
        {label: 'Название файла', field: 'fname'},
        {label: 'Наименование товара', field: 'title'},
        {label: 'Марка/Модель', field: 'model'},
        {label: 'ГОСТ', field: 'gost'},
        {label: 'Производитель', field: 'producer'},
        {label: 'Гарантийный срок', field: 'warranty_period'},
    ];
    public expand = true;
    
    get offers() {                                                              // Получаем из хранилища список товаров
        var of = readOffers(this.$store)
        for (var i=0; i < of.length; i++){
            var file_path = of[i]['ya_path'].split('/')
            of[i]['fname'] = file_path[file_path.length - 1]
            of[i]['link'] = of[i]['ya_path'].replace('disk:/', 'https://disk.yandex.ru/client/disk/')
        }
        return of;
    }
    
    get offers_count() {                                                        // Возвращаем количество найденных товаров
        return this.$store.getters.offers.length
    }
    public async mounted() {
        await this.search_offers()

    }
    public created() {                                                          // На создание страницы
        const offerFilter = readOffersFilter(this.$store);                      // Получаем из хранилища offerFilter

        if (offerFilter) {                                                      // и заполняем им форму
            this.offers_search = offerFilter.offers_search;
        }
       
        const rowsPerPage = readRowsPerPage(this.$store);                       // Получаем из хранилища сохраненное количество товаров на странице                    
        this.pagination.rowsPerPage = (rowsPerPage) ? rowsPerPage : 10          // Если меняли кол-во товаров, то ставим сохраненное, иначе - 10
    }

    public async updatePagination (pagination) {                                // На изменение кол-ва товаров на странице:
        await dispatchUpdateRowsPerPage(this.$store, pagination.rowsPerPage);   // записываем в хранилище кол-во товаров на странице            
    }

    public async search_offers() {                                              // на кнопку "Искать"
        const offerFilter: IOfferFilter = {};                                   // получаем шаблон фильтра
        offerFilter.offers_search = this.offers_search;                         // запихиваем в шаблон данные из формы

        await dispatchUpdateOfferFilter(this.$store, offerFilter);              // Сохраняем в хранилище данные из формы
    }
}
